* {
  box-sizing: border-box;
}

p{
  margin-block-end: 0.4em;
  margin-block-start: 0.2em;
}

body {
  background-color: black;
}

header {
  position: relative;
  min-height: 80vh;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.home{
  position: relative;
  min-height: 92.5vh;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

textarea{
  resize: vertical;
}

h6{
  margin-block-end: 1em;
  margin-block-start: 1em;
}

.about-header{
  position: relative;
  height: 70vh;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.form-header{
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.form{
  text-align:center; 
  vertical-align:middle;
}

.form-header .banner {
  display: inline-block;
  vertical-align: middle;
  margin-top: 22.5vh;
  width: 60%;
  padding-bottom: 30px;
  text-align: center;
}

.images{
  display: grid;
  grid-template-columns: 50% 50%;
}

#contact-form{
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; 
}

header .banner {
  display: inline-block;
  vertical-align: middle;
  margin-top: 27.5vh;
  width: 60%;
  padding-bottom: 30px;
  text-align: center;
}

.about-header-team{
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.about-header .banner {
  display: inline-block;
  vertical-align: middle;
  margin-top: 22.5vh;
  width: 80%;
  padding-bottom: 30px;
  text-align: center;
}


.about-header .banner-team {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5vh;
  width: 80%;
  padding-bottom: 30px;
  text-align: center;
}

.about-header-team .banner-team {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5vh;
  width: 80%;
  padding-bottom: 30px;
  text-align: center;
}

.parallax .banner {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10vh;
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: center;
  color: white;
}


.parallax .banner .text {
  font-size: 22px;
}

.about-header .banner .text {
  font-size: 22px;
}

.title-one {
  font-family: "Roobert", Helvetica, Arial, sans-serif;
  color: white;
  font-weight: 400;
}

h1 {
  font-size: 50px;
}

.title-two {
  font-family: "Cinzel", serif;
  color: white;
  font-weight: 500;
}

.text {
  font-family: "Raleway", serif;
  color: white;
  font-weight: 600;
  font-size: 16px
}

.text-team {
  font-family: "Raleway", serif;
  color: white;
  font-weight: 600;
  font-size: 20px
}

.text-app {
  font-family: "Raleway", serif;
  color: black;
  font-weight: 600;
  font-size: 16px
}

.text-caption {
  font-family: "Raleway", serif;
  color: white;
  font-weight: 600;
  font-size: 12px
}

.dynamic-title {
  font-family: "Raleway", serif;
  color: black;
  font-weight: 600;
  font-size: 22px;
  text-align: center
}

.recording-studio-title {
  font-family: "Raleway", serif;
  color: black;
  font-weight: 600;
  font-size: 20px;
  text-align: center
}

.recording-studio{
  background-color: silver;
  margin-top: 5%;
  border-radius: 4px;
  margin-left: 0.1%;
  margin-right: 0.1%;
}


.parallax {
  /* The image used */
  background: url("graingermuseum.JPG");
  /* Set a specific height */
  height: 550px;
  width: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.65);
}

#dynamic-definition {
  font-family: "Raleway", serif;
  color: black;
  font-weight: 600;
  font-size: 20px;
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-top: 20%;
  margin-bottom: 30%;
  border-radius: 3px;
  text-align: center;
  background-color: silver;
}

#time-text{
  text-align: right;
}

.card {
  background-color: white;
  height: 35%;
  padding: 5%;
  width: 60%;
  overflow-y: visible;
  margin-top: 25%;
  float: center;
}

.modal {
  background-color: black;
  opacity: 1;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.popup-content {
  background-color: grey;
  opacity: 0.9;
  width: 100%
}
.dynamic-title {
  margin-bottom: -15%;
}

.marimba-back{
  background-color: #633200;
  opacity: 0.8;
  padding: 1.5%;
}


.container {
  display: grid;
  grid-template-columns: repeat(22, auto);
  margin-top: -5%;
  z-index: -1;
  position:relative;
}

.container-top{
  display: grid;
  grid-template-columns: repeat(22, auto);
  margin-left: 2.5%;
}

.note {
  width: 97.5%;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  text-align: center;
  user-select: none;
  background-color: rgb(219, 187, 144);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  transform-origin: top center;
  align-items: flex-end;
  display: flex;
}

.note-empty{
  width: 25px
}

.note-text-top{
  margin-left:15%
}

.note-text{
  margin-left:30%
}

.note:hover {
background-image: linear-gradient(-180deg, #c09864 3%, #fa3636 98%);
transform: rotateX(-5deg);
}

.note.note-1 {
height: 163px;
}
.note.note-2 {
height: 158px;
margin-top: 7.5%
}
.note.note-3 {
height: 148px;
margin-top: 20%
}
.note.note-4 {
height: 143px;
margin-top: 27%
}
.note.note-5 {
height: 133px;
margin-top: 42%
}
.note.note-6 {
height: 128px;
margin-top: 49%
}
.note.note-7 {
height: 123px;
margin-top: 59%;
transform: translateX(-25%)
}
.note.note-8 {
height: 113px;
margin-top: 71%;
transform: translateX(-25%)
}
.note.note-9 {
height: 108px;
margin-top: 79%;
transform: translateX(-25%)
}
.note.note-10 {
height: 98px;
margin-top: 91%;
transform: translateX(-15%)
}
.note.note-11 {
height: 93px;
margin-top: 98%;
transform: translateX(-15%)
}
.note.note-12 {
height: 88px;
margin-top: 100%;
transform: translateX(-15%)
}
.note.note-13 {
height: 163px;
}
.note.note-14 {
height: 158px;
}
.note.note-15 {
height: 153px;
}
.note.note-16 {
height: 148px;
}
.note.note-17 {
height: 143px;
}
.note.note-18 {
height: 138px;
}
.note.note-19 {
height: 133px;
}
.note.note-20 {
height: 128px;
}
.note.note-21 {
height: 123px;
}
.note.note-22 {
height: 118px;
}
.note.note-23 {
height: 113px;
}
.note.note-24 {
height: 108px;
}
.note.note-25 {
height: 103px;
}
.note.note-26 {
height: 98px;
}
.note.note-27 {
height: 93px;
}
.note.note-28 {
height: 88px;
}
.note.note-29 {
height: 83px;
}
.note.note-30 {
height: 78px;
}
.note.note-31 {
height: 78px;
}
.note.note-32 {
height: 78px;
}
.note.note-33 {
height: 78px;
}
.note.note-34 {
height: 78px;
}
.note.note-35 {
height: 72px;
}
.note.note-36 {
height: 67px;
}
.note.note-37 {
height: 62px;
}
.note.note-38 {
height: 80px;
margin-top: 107.5%;
transform: translateX(-15%)
}

#shuffled-notes{
  transform: translateX(80px);
}

#shuffled-notes-fs5{
  transform: translateX(120px);
}

#shuffled-notes-gs5{
  transform: translateX(60px);
}

#shuffled-notes-as5{
  transform: translateX(-20px);
}

.recordingDiv {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 100%;
}
.carouselDivSpinner {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 100%;
  height: 30vh;
}

.footer{
  text-align: center;
}

.carouselDiv {
  display: grid;
  grid-template-columns: 40% auto;
  width: 100%;
  height: 30vh;
}

.MuiLinearProgress-barColorPrimary {
  background-color: black;
}

.rack {
  stroke: #7c3f00;
  stroke-width: 7;
}

.MuiLinearProgress-colorPrimary {
  background-color: #e4e4e4;
}

.notes {
  font-size: 3.5px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid-item {
  border-bottom: 1px solid #000;
  padding-bottom: -25px;
}

.play-buttons {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  grid-row-gap: 10%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}

.item1 {
  grid-column-start: 2;
  grid-column-end: 2;
}

.App {
  text-align: center;
  margin-top: 30px;
}

.Slider {
  margin-left: 30%;
  margin-right: 30%;
}

.picker-item {
  font-size: 15px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.knob {
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 10%;
  padding-top: 10%;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.knob-outer{
  background-color: silver;
  border-radius: 10px;
  height: 25vh;
} 

.App-title {
  font-size: 1.5em;
}

.MuiTab-textColorInherit {
  color: grey;
}

.MuiTab-textColorInherit.Mui-selected {
  color: rgb(90, 88, 88);
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: rgb(90, 88, 88);
}

.App-intro {
  font-size: large;
}

.playingStyle {
  display: grid;
  grid-template-columns: auto auto auto;
}

.record-inner{
  display: grid;
  grid-template-columns: 30% 40% 30%;
}

.dynamic-inner{
  display: grid;
  grid-template-columns: 50% 50%;
}

.spinnerDiv{
  margin-right: auto;
  margin-left: auto;
  margin-top: 15vh;
  display: block;
}


#record-button{
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-bottom: 2%
}

.headshot{
  height: 200px;
  width: 195px;
}

.playingStyle-inner {
  display: grid;
  grid-template-columns: 11% 80% 9%;
  }

#download-link {
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-bottom: 2%
}

#progress-bar {
  margin-top: 2.5%;
  margin-bottom: 1.5%;
}

.knob-text{
  font-family:  'Montserrat', sans-serif;
  font-size: 14px;
  color: black;
  padding-left: 5%;
  padding-right: 5%
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}


.menu-wrapper {
  width: 90%;
}
.menu-item {
  padding: 0px;
  width: 120px;
  user-select: none;
  cursor: pointer;
  border: 1px transparent solid;
  color: black;
  outline: none;
}
.menu-item-wrapper {
  border: 1px transparent solid;
}

.menu-item.active {
  outline: none;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.column {
  float: left;
  width: 33.33%;
  padding-left: 20px;
  padding-right: 20px;
}

.column2 {
  float: left;
  width: 20%;
  padding-left: 0px;
  padding-top: 15px;
  background-color: silver;
  height: 100vh;
}

.column3 {
  float: left;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.row-item {
  text-align: center;
}

.page-sub-title {
  margin-top: 5%;
  font-family: "Raleway", serif;
  font-weight: bold;
  font-size: 20px
}

.bell-middle{
  background-color: grey
}

.bell-five {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  margin-top: 2%;
  margin-left: 5%
}

.bell-seven {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin-top: 2%;
  margin-left: 5%
}

.bell-main {
  display: grid;
  grid-template-columns: 10px auto 10px;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.bell-bar-h {
  margin-top: -10px;
  height: 10px;
  background-color: rgb(189, 130, 53);
}

.bell-sidebar {
  width: 10px;
  background-color: rgb(189, 130, 53);
}

.BrainhubCarousel__arrows {
  background-color: transparent;
}

.picker-scroller{
  background-color: silver;
}

.picker-container .picker-column .picker-item{
  color:#ffffff
}

.logo-div {
  display: grid;
  grid-template-columns: 50% 50%;
}

.logo {
  margin-bottom: 5%;
  outline: none;
}

.knob-inner{
  margin-left: 22.5%;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.scroll-menu-div {
  padding-left: 0%;
  padding-right: 0%;
}

.value {
  font-family: "Work Sans", sans-serif;
  fill: black;
  font-size: 30px;
}

.repeats {
  margin-left: 45%;
  margin-right: 45%;
}

html,
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

#App {
  /* Give app full page to work with */
  height: 90vh;
}

#page-wrap {
  text-align: center;
  /* Prevent sidebar from showing a scrollbar on page */
}

/* Individual item */
.bm-item {
  display: grid;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 5px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: silver;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: grey;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  height: 95%;
}

.form-field{
  size: 50
}

@media only screen and (min-width: 1500px) {
  .carouselDiv {
    width: 90%;
  }

  .container{
    margin-top: -2%;
    grid-template-columns: repeat(22, 2.5vw);
  }

  .container-top{
    grid-template-columns: repeat(22, 2.5vw);
  }

  .marimba-back{
    margin-left: 17.5%;
    margin-right: 17.5%;
  }


  .knob-inner{
    margin-left: 27.5%;
  }

  .bell-main{
    margin-left: 10%;
    margin-right: 10%;
  }
  
}

@media only screen and (min-width: 576px) and (max-width: 770px) {
  .page-title {
    margin-left: 0%;
    font-size: 20px;
  }

  .parallax{
    height : 650px
  }

  .knob-outer{
    height: 17.5vh;
  }

  .know-outer{
    height: 20vh;
  }

.picker-container{
  margin-top: -5%
}

.recording-studio{
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 20%;
}

#dynamic-definition{
  font-size: 17px;
  margin-top: 55%;
  margin-bottom: 45%;
}

  
.bell-main {
  display: grid;
  grid-template-columns: 10px auto 10px;
  margin-top: 5%;
  margin-bottom: 5%;
}
  
  .knob-inner{
    margin-left: 17.5%;
  }

  #row-inner {
    width: 100%;
  }

  .container-top{
    margin-left: 1%;
  }

  .carouselDiv {
    width: 90%;
    grid-template-columns: auto;
  }

  .page-sub-title {
    margin-left: 0%;
    font-size: 20px;
  }

  .knob-text{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
  }

  .MuiTab-wrapper {
    font-size: 12px;
  }

  .column2 {
    float: left;
    width: 100%;
    padding-left: 0px;
    padding-top: 15px;
    background-color: silver;
    height: 20vh;
  }

  .logo {
    margin-bottom: 5%;
    outline: none;
  }

  .column3 {
    width: 100%;
    bottom: 0;
    text-align: center;
    background-color: #e4e4e4;
  }

  .knob {
    padding: 10%;
  }

  .App {
    text-align: center;
    margin-top: 10%;
  }

  .note.note-7 {
    transform: translateX(-45%);
    margin-top: 70%;
    }
    .note.note-8 {
    transform: translateX(-45%);
    margin-top: 95%;
    }
    .note.note-9 {
    transform: translateX(-45%);
    margin-top: 110%;
    }
    .note.note-10 {
    transform: translateX(-70%);
    margin-top: 135%;
    }
    .note.note-11 {
    transform: translateX(-70%);
    margin-top: 150%
    }
    .note.note-12 {
    margin-top: 190%;
    transform: translateX(-80%)
    }
    .note.note-38 {
      margin-top: 200%;
      transform: translateX(-80%)
    }

    #shuffled-notes{
      transform: translateX(40px);
    }
    
    #shuffled-notes-fs5{
      transform: translateX(70px);
    }
    
    #shuffled-notes-gs5{
      transform: translateX(30px);
    }

    #shuffled-notes-as5{
      transform: translateX(-10px);
    }

}


@media only screen and (max-width: 576px) {

  h1{
    font-size: 30px;
  }
  .page-title {
    margin-left: 0%;
    font-size: 20px;
  }

  .bell-five {
    grid-template-columns: 17vw 17vw 17vw 17vw 17vw;
    margin-left: 0%;
  }

  .bell-seven{
    grid-template-columns: 12vw 12vw 12vw 12vw 12vw 12vw 12vw;
    margin-left: 0%;
  }

header .banner{
  margin-top: 20vh;
  width: 75%
}

.note.note-1 {
  transform: translateY(17.5%);
}

.note.note-2 {
  transform: translateY(20%);
}

.note.note-3 {
  transform: translateY(28%);
}

.note.note-4 {
  transform: translateY(32.5%);
}

.note.note-5 {
  transform: translateY(40%);
}

.note.note-6 {
  transform: translateY(45%);
}

.note.note-7 {
  transform: translateY(50%);
}

.note.note-8 {
  transform: translateY(62.5%);
}

.note.note-9 {
  transform: translateY(67.5%);
}

.note.note-10 {
  transform: translateY(80%);
}

.note.note-11 {
  transform: translateY(90%);
}

.note.note-12 {
  transform: translateY(100%);
}

.note.note-38 {
  transform: translateY(120%);
}

.parallax .banner .text {
  font-size: 17px;
}

.parallax{
  height: 100vh
}

.about-header .banner{
  margin-top: 17.5vh;
}

.about-header .banner .text {
  font-size: 17px;
}

.picker-container{
  margin-top: -5%
}

.recording-studio{
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 2.5%;
}

#dynamic-definition{
  font-size: 17px;
  margin-top: 25%;
  margin-bottom: 5%;
}

.headshot{
  height: 150px;
  width: 130px;
}

  
.bell-main {
  display: grid;
  grid-template-columns: 10px auto 10px;
  margin-top: 5%;
  margin-bottom: 5%;
}
  
  .knob-inner{
    margin-left: 17.5%;
  }

  #row-inner {
    width: 100%;
  }

  .container-top{
    margin-left: 1%;
  }

  .carouselDiv {
    width: 90%;
    grid-template-columns: auto;
  }

  .page-sub-title {
    margin-left: 0%;
    font-size: 20px;
  }

  .knob-text{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: black;
  }

  .MuiTab-wrapper {
    font-size: 12px;
  }

  .column2 {
    float: left;
    width: 100%;
    padding-left: 0px;
    padding-top: 15px;
    background-color: silver;
    height: 20vh;
  }

  .logo {
    margin-bottom: 5%;
    outline: none;
  }

  .column3 {
    width: 100%;
    bottom: 0;
    text-align: center;
    background-color: #e4e4e4;
  }

  .knob {
    padding: 5%;
  }

  .App {
    text-align: center;
    margin-top: 10%;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(22, 3.9vw);
    margin-top: -5%;
    z-index: -1;
    position:relative;
    margin-bottom: 5%;
  }
  
  .container-top{
    display: grid;
    grid-template-columns: repeat(22, 3.9vw);
    margin-left: 2.5%;
  }

  .note{
    font-size: 7px;
    text-align: left;
  }

  .form-field{
    size: 20
  }

  #shuffled-notes{
    transform: translateX(0px);
  }
  
  #shuffled-notes-fs5{
    transform: translateX(0px);
  }
  
  #shuffled-notes-gs5{
    transform: translateX(0px);
  }
  
  #shuffled-notes-as5{
    transform: translateX(0px);
  }
  
}






